import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import L from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import {
  Page,
  Container,
  ButtonContainer,
  PopupButtonContainer,
  SpinnerWrapper,
} from './SubplotMapScreen.styles';
import { getCurrentLocation } from '../../services/geolocation';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import CustomButton from '../../components/CustomButton/CustomButton';
import { colors } from '../../constants/colors';
import addIcon from '../../assets/icons/plus.svg';
import evaluation from '../../assets/icons/evaluation.svg';
import { resetStateProperty } from '../../store/actions/properties';
import PopUp from '../../components/PopUp/PopUp';
import { getEvaluationData } from '../../services/local';
import { customIcon, locationIcon } from '../../helpers/mapIcons';

const SubplotMapScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const evaluationId = location?.state?.evaluationId;
  const params = useParams();
  const propertyId = params?.id;
  const savedProperties = useSelector((state) => state.savedData.properties);
  const property = savedProperties.find((item) => item.id === propertyId);
  const selectedSubplot = property?.subplots?.find(
    (i) => i.id === params?.plot_id,
  );
  const storedEvaluationData = getEvaluationData();

  const [currentLocation, setCurrentLocation] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [liveLocation, setLiveLocation] = useState(null);
  const [pointEvaluatedFlag, setPointEvaluatedFlag] = useState(false);

  const [selectedPoint, setSelectedPoint] = useState(null);
  const [point, setPoint] = useState(null);

  // get live location every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      getCurrentLocation().then((value) => setLiveLocation(value));
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!currentLocation) {
      getCurrentLocation().then((value) =>
        setCurrentLocation([value?.lat, value?.lng]),
      );
    }
  }, [currentLocation]);

  const handleClick = (latlng) => {
    setSelectedPoint(latlng);
    setOpenAlert(true);
  };

  const handlePointClick = useCallback(
    (latlng, marker) => {
      const subplotPointId = property?.subplots
        ?.filter((subplot) => subplot?.id === selectedSubplot?.id)[0]
        ?.points?.filter(
          (point) => point?.identification === marker?.name,
        )[0]?.id;
      const pTypeId = property?.subplots
        ?.filter((subplot) => subplot?.id === selectedSubplot?.id)[0]
        ?.points?.filter((point) => point?.identification === marker?.name)[0]
        ?.pointType?.id;
      const isPointEvaluated =
        storedEvaluationData &&
        !!storedEvaluationData.filter(
          (point) => point?.pointId === subplotPointId,
        ).length;
      if (isPointEvaluated) {
        return setPointEvaluatedFlag(true);
      }

      setSelectedPoint(latlng);
      setPoint({
        typeId: pTypeId,
        id: subplotPointId,
        name: marker?.name,
      });
    },
    [property, selectedSubplot, storedEvaluationData],
  );

  useEffect(() => {
    const fromLatLng = L.latLng(liveLocation);
    selectedSubplot?.markers?.forEach((marker) => {
      const distance = fromLatLng?.distanceTo([marker?.lat, marker?.lng]);
      // live distance in meters
      if (distance < 2) {
        handlePointClick({ lat: marker?.lat, lng: marker?.lng }, marker);
      }
    });
  }, [liveLocation, currentLocation, selectedSubplot, handlePointClick]);

  const backHandler = () => {
    dispatch(resetStateProperty());
    navigate(-1);
  };

  const close = () => {
    setOpenAlert(false);
  };

  const closePointAlert = () => {
    setPoint(null);
  };

  const closeEvaluatedFlag = () => {
    setPointEvaluatedFlag(false);
  };

  return (
    <Page>
      <TopBar
        back={backHandler}
        title={selectedSubplot?.identification}
        subtitle={t('evaluate')}
      />
      <PopUp open={pointEvaluatedFlag} close={closeEvaluatedFlag}>
        <p className="popup-title">{t('already_evaluated_point')}</p>
        <PopupButtonContainer>
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            text={t('close')}
            backgroundColor={colors.primaryColor}
            type="button"
            onClick={() => {
              closeEvaluatedFlag();
            }}
          />
        </PopupButtonContainer>
      </PopUp>
      <PopUp open={!!point} close={closePointAlert}>
        <p className="popup-title">{`${t('location_in')} ${point?.name}`}</p>
        <PopupButtonContainer>
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            icon={evaluation}
            text={t('start_evaluation')}
            backgroundColor={colors.primaryColor}
            type="submit"
            onClick={() => {
              navigate(
                generatePath(routes.SUBPLOT_EVALUATE.path, {
                  id: propertyId,
                  plot_id: params?.plot_id,
                }),
                {
                  state: {
                    pointId: point?.id,
                    pointName: point?.name,
                    evaluationId,
                    pointTypeId: point?.typeId,
                  },
                },
              );
            }}
          />
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            icon={addIcon}
            text={t('update_point')}
            backgroundColor={colors.primaryColor}
            type="submit"
            onClick={() => {
              navigate(
                generatePath(routes.UPDATE_POINT_SUBPLOT.path, {
                  id: propertyId,
                  plot_id: params?.plot_id,
                }),
                {
                  state: { selectedPos: selectedPoint, pointName: point?.name },
                },
              );
            }}
          />
        </PopupButtonContainer>
      </PopUp>
      <PopUp open={openAlert} close={close}>
        <PopupButtonContainer>
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            icon={evaluation}
            text={t('add_pe')}
            backgroundColor={colors.primaryColor}
            type="submit"
            onClick={() => {
              navigate(
                generatePath(routes.ADDAP_SUBPLOT.path, {
                  id: params?.id,
                  plot_id: params?.plot_id,
                }),
                { state: { selectedPos: selectedPoint } },
              );
            }}
          />
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            icon={addIcon}
            text={t('update_ap')}
            backgroundColor={colors.primaryColor}
            type="submit"
            onClick={() => {
              navigate(
                generatePath(routes.UPDATE_AP_SUBPLOT.path, {
                  id: params?.id,
                  plot_id: params?.plot_id,
                }),
                { state: { selectedPos: selectedPoint } },
              );
            }}
          />
        </PopupButtonContainer>
      </PopUp>
      {currentLocation?.length ? (
        <Container>
          <MapContainer
            center={currentLocation}
            zoom={14}
            scrollWheelZoom={false}
            whenReady={(map) => {
              map.target.on('click', (e) => {
                handleClick(e?.latlng);
              });
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {selectedSubplot?.polyline && (
              <Polyline positions={selectedSubplot.polyline} />
            )}
            <Marker position={currentLocation} icon={locationIcon} />
            {selectedSubplot?.markers?.map((marker, index) => (
              <Marker
                key={index}
                position={[marker?.lat, marker?.lng]}
                icon={customIcon}
                eventHandlers={{
                  click: (e) => {
                    handlePointClick(e?.latlng, marker);
                  },
                }}
              />
            ))}
          </MapContainer>
        </Container>
      ) : (
        <SpinnerWrapper>
          <Spinner animation="border" variant="dark" />
        </SpinnerWrapper>
      )}
      <ButtonContainer>
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          icon={addIcon}
          text={t('add_pe')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(
              generatePath(routes.ADDAP_SUBPLOT.path, {
                id: params?.id,
                plot_id: params?.plot_id,
              }),
            );
          }}
        />
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          icon={addIcon}
          text={t('update_ap')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(
              generatePath(routes.UPDATE_AP_SUBPLOT.path, {
                id: params?.id,
                plot_id: params?.plot_id,
              }),
            );
          }}
        />
      </ButtonContainer>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default SubplotMapScreen;
