export const LOGIN = '/Auth/Login';
export const LOGOUT = '/Auth/Logout';
export const RECOVER_PASSWORD = '/Account/ResendResetUserPasswordEmail';
export const PROPERTIES = '/Property/GetProperties';
export const PROPERTY = '/Property/GetPropertyDetails';
export const UPDATE_PROPERTY_AP = '/Property/UpdatePropertyAccessPoint';
export const UPDATE_SUBPLOT_AP = '/Property/UpdatePropertySublotAccessPoint';
export const CREATE_SUBPLOT_POINT = '/Property/CreateSubplotPoint';
export const UPDATE_SUBPLOT_POINT = '/Property/UpdatePropertySubplotPoint';
export const GET_GENERAL_HELP = '/Helper/GetGeneralHelp';
export const GET_POINT_TYPE_INDICATORS = '/Indicator/PointTypeIndicators';
export const GET_POINT_TYPES = '/Indicator/GetPointTypes';
export const SEND_EVALUATION = '/Evaluation/SendEvaluation';
export const SEND_EVALUATION_IMAGES = '/Evaluation/SendEvaluationImage';
export const GET_STATS = '/Evaluation/Statistics';
