import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Title,
  Subtitle,
  Back,
  Logout,
  Info,
  Download,
  Evaluation,
  Approved,
  Statistics,
} from './TopBar.styles';
import backIcon from '../../assets/icons/TopBar/back.svg';
import infoIcon from '../../assets/icons/TopBar/info.svg';
import logoutIcon from '../../assets/icons/TopBar/logout.svg';
import downloadIcon from '../../assets/icons/TopBar/download.svg';
import xIcon from '../../assets/icons/TopBar/x.svg';
import checkIcon from '../../assets/icons/TopBar/check.svg';
import statisticsIcon from '../../assets/icons/TopBar/statistics.svg';
import { logout } from '../../store/actions/auth';
import { saveData } from '../../store/actions/savedData';

const TopBar = (props) => {
  const {
    back,
    title,
    subtitle,
    logOut,
    download,
    evaluation,
    approved,
    statistics,
    info,
    propertyId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const savedProperties = useSelector((state) => state.savedData.properties);

  const onStorage = savedProperties
    .map((property) => property.id)
    .includes(propertyId);

  const logoutHandler = useCallback(
    (user) => {
      dispatch(logout(user))
        .then(() =>
          // eslint-disable-next-line no-undef
          alert(t('logged_out')),
        )
        .catch(() =>
          // eslint-disable-next-line no-undef
          alert(t('error_loggout')),
        );
    },
    [dispatch, t],
  );

  const downloadProperty = useCallback(() => {
    dispatch(saveData(propertyId))
      .then(() =>
        // eslint-disable-next-line no-undef
        alert(t('downloaded')),
      )
      .catch((error) =>
        // eslint-disable-next-line no-undef
        alert(error),
      );
  }, [dispatch, propertyId, t]);

  return (
    <Container>
      {back && <Back src={backIcon} onClick={() => back()} />}
      {logOut && (
        <Logout type="button" onClick={() => logoutHandler(user)}>
          <img src={logoutIcon} alt="logout" />
        </Logout>
      )}
      {info && (
        <Info onClick={() => info()}>
          <img src={infoIcon} alt="info" />
        </Info>
      )}

      <>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </>
      {download && onStorage && (
        // I added here the download click because we may need to update the saved data
        <Download type="button" onClick={() => downloadProperty()}>
          <img src={downloadIcon} alt="download" />
          <img className="storage" src={checkIcon} alt="check" />
        </Download>
      )}
      {download && !onStorage && (
        <Download type="button" onClick={() => downloadProperty()}>
          <img src={downloadIcon} alt="download" />
          <img className="storage" src={xIcon} alt="x" />
        </Download>
      )}
      {statistics && (
        <Statistics type="button" onClick={() => statistics()}>
          <img src={statisticsIcon} alt="statistics" />
        </Statistics>
      )}
      {evaluation && (
        <Evaluation>
          <div className="title">{t('evaluation')}</div>
          <div className="content">
            {evaluation?.done} / {evaluation?.outOf}
          </div>
        </Evaluation>
      )}
      {approved && (
        <Approved>
          <div className="title">{t('approval')}</div>
          <div className="content">{approved}</div>
        </Approved>
      )}
    </Container>
  );
};

export default TopBar;
