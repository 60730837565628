import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  width: 100%;
  gap: 30px;
  padding: 5px;
`;

export const Title = styled.label`
  font-weight: 700;
  color: ${colors.primaryColor};
  font-size: 24px;
  text-align: center;
`;

export const Header = styled.label`
  font-weight: 700;
  color: ${colors.text};
  font-size: 18px;
  text-align: center;
`;

export const Content = styled.label`
  font-weight: 400;
  color: ${colors.text};
  font-size: 16px;
  text-align: center;
`;

export const InputWrapper = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-weight: 400;
  color: ${colors.text};
  font-size: 16px;
  text-align: center;

  input {
    margin-right: 10px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  .button {
    border-radius: 100%;
    border: none;
    height: 35px;
    width: 35px;
    box-shadow: 0 0 5px ${colors.shadow2};
    background-color: ${colors.white};
    img {
      display: flex;
      margin: auto;
      margin-top: -2px;
    }
    .storage {
      margin-left: 22px;
      height: 16px;
      width: 16px;
    }
  }
`;
