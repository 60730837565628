import axios from 'axios';
import * as Endpoints from './endpoints';
import { clearLocalStorage } from '../local';
import { LOGOUT_SUCCESS } from '../../store/actions/types';
import { store } from '../../store';

export { Endpoints };

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // eslint-disable-next-line no-undef
    'Accept-Language': navigator?.language || 'pt-PT',
  },
});

export default axiosClient;

// Add a response interceptor
axiosClient.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401) {
      store.dispatch({ type: LOGOUT_SUCCESS });
      clearLocalStorage();
      // eslint-disable-next-line no-undef
      window.location.replace(process.env.REACT_APP_URL);
      return Promise.resolve();
    }

    return Promise.reject(error);
  },
);

export const getErrorMessage = (error, defaultMessage) => {
  let message = error?.response?.data?.message;

  if (message) {
    const errors = Object.values(error?.response?.data?.errors);

    if (errors) {
      message += '\n';
    }

    errors?.forEach((validations) => {
      validations.forEach((validation) => {
        message += `\n${validation}`;
      });
    });

    return message;
  }

  return defaultMessage;
};
