/* eslint-disable no-throw-literal */
/* eslint-disable no-restricted-syntax */
// ///////////////////////////
//            Utils         //
// ///////////////////////////

export const normalizeStr = (str) =>
  str
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const extractPolyline = async (plainText) => {
  // eslint-disable-next-line no-undef
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(plainText, 'text/xml');
  const finalPolyline = [];

  if (xmlDoc.documentElement.nodeName === 'kml') {
    for (const item of xmlDoc.getElementsByTagName('Polygon')) {
      const polygons = item.getElementsByTagName('LinearRing');

      /** POLYGONS PARSE * */
      for (const polygon of polygons) {
        const coords = polygon
          .getElementsByTagName('coordinates')[0]
          .childNodes[0].nodeValue.trim();
        const points = coords.split(' ');

        const finalPolylinePath = [];
        for (const point of points) {
          const coord = point.split(',');
          finalPolylinePath.push({
            lat: isNaN(+coord[1]) ? 0 : +coord[1],
            lng: isNaN(+coord[0]) ? 0 : +coord[0],
          });
        }
        finalPolyline.push(finalPolylinePath);
      }
    }
  }

  return { polyline: finalPolyline };
};

export const extractPoints = async (plainText) => {
  // eslint-disable-next-line no-undef
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(plainText, 'text/xml');
  const markersFinal = [];

  if (xmlDoc.documentElement.nodeName === 'kml') {
    for (const item of xmlDoc.getElementsByTagName('Placemark')) {
      const markers = item.getElementsByTagName('Point');
      const name = item.getElementsByTagName('name');

      /** MARKER PARSE * */
      for (const marker of markers) {
        const coords = marker
          .getElementsByTagName('coordinates')[0]
          .childNodes[0].nodeValue.trim();

        const coord = coords.split(',');
        markersFinal.push({
          lat: isNaN(+coord[1]) ? 0 : +coord[1],
          lng: isNaN(+coord[0]) ? 0 : +coord[0],
          name: name[0].innerHTML,
        });
      }
    }
  }

  return { markers: markersFinal };
};

// ///////////////////////////
//             D1           //
// ///////////////////////////
// Function to suggest a level based on the values filled on the fields
// copse - Nr of copse
// bushes - Nr of bushes
// rocks - Nr of rocks
// puddle - Nr of puddle
// pond - Nr of pond
// riparianGallery - Nr of riparianGallery
// Returns Levels 1-4 (Bad, Medium, Good, Excellent)

export const levelSuggestionD1 = (
  copse,
  bushes,
  rocks,
  puddle,
  pond,
  riparianGallery,
) => {
  if (!(copse || bushes || rocks || puddle || pond || riparianGallery)) {
    return 1;
  }
  if (copse && !(bushes || rocks || puddle || pond || riparianGallery)) {
    return 2;
  }
  if (bushes && !(copse || rocks || puddle || pond || riparianGallery)) {
    return 2;
  }
  if (rocks && !(bushes || copse || puddle || pond || riparianGallery)) {
    return 2;
  }
  if (puddle && !(bushes || rocks || copse || pond || riparianGallery)) {
    return 2;
  }
  if (pond && !(bushes || rocks || puddle || copse || riparianGallery)) {
    return 2;
  }
  if (riparianGallery && !(bushes || rocks || puddle || pond || copse)) {
    return 2;
  }
  if (copse && bushes && !(rocks || puddle || pond || riparianGallery)) {
    return 3;
  }
  if (copse && rocks && !(bushes || puddle || pond || riparianGallery)) {
    return 3;
  }
  if (rocks && bushes && !(copse || puddle || pond || riparianGallery)) {
    return 3;
  }
  if (puddle && pond && !(copse || rocks || bushes || riparianGallery)) {
    return 3;
  }
  if (puddle && riparianGallery && !(copse || rocks || bushes || pond)) {
    return 3;
  }
  if (riparianGallery && pond && !(copse || rocks || bushes || puddle)) {
    return 3;
  }
  if (copse && bushes && rocks && !(puddle || pond || riparianGallery)) {
    return 3;
  }
  if (puddle && pond && riparianGallery && !(copse || bushes || rocks)) {
    return 3;
  }
  return 4;
};

// ///////////////////////////
//           D3.1.1         //
// ///////////////////////////
// Function to suggest a level based on the values filled on the fields
// bushy -> If it is Bushy
// vine -> If it has Vies
// visible -> If the vines are really visible or not
// ligthLevel -> % of ligth that reaches the ground in 4 levels (|>75%| |>50%| |>25%| |<25%|) levels 1 to 4 (integer)
// Returns Levels 1-4 (Bad, Medium, Good, Excellent)

export const levelSuggestionD311 = (bushy, vine, visible, ligthLevel) => {
  if (bushy && vine && visible && ligthLevel === 4) {
    return 4;
  }
  if (vine && ligthLevel >= 3) {
    return 3;
  }
  if (bushy && ligthLevel >= 2) {
    return 2;
  }
  return 1;
};

// ///////////////////////////
//           D3.2           //
// ///////////////////////////
// Function to suggest a level based on the values filled on the fields
// dominant -> If the thing is dominant
// bushes -> Nr of bushes
// coverageLevel -> % of space covered by bushes in 3 levels (|>25%| |>50%| |>75%|) levels 1 to 3 (integer)
// Returns Levels 1-4 (Bad, Medium, Good, Excellent)

export const levelSuggestionD32 = (dominant, bushes, coverageLevel) => {
  if (!dominant && bushes >= 4 && coverageLevel === 3) {
    return 4;
  }
  if (!dominant && bushes >= 4 && coverageLevel === 2) {
    return 3;
  }
  if (!dominant && bushes >= 2) {
    return 2;
  }
  return 1;
};

// ///////////////////////////
//           D3.3           //
// ///////////////////////////
// Function to suggest a level based on the values filled on the fields
// Presence of the following:
// slits -> boolean
// succulentPlants -> boolean
// fetuses -> boolean
// mosses -> boolean
// lichens -> boolean
// Returns Levels 1-4 (Bad, Medium, Good, Excellent)

export const levelSuggestionD33 = (
  slits,
  succulentPlants,
  fetuses,
  mosses,
  lichens,
) => {
  if (
    slits &&
    [succulentPlants, fetuses, mosses, lichens].filter(Boolean).length >= 3
  ) {
    return 4;
  }
  if (
    slits &&
    [succulentPlants, fetuses, mosses, lichens].filter(Boolean).length > 0
  ) {
    return 3;
  }
  if (!slits && !succulentPlants && !fetuses && !mosses && !lichens) {
    return 1;
  }
  return 2;
};

// ///////////////////////////
//           D3.4           //
// ///////////////////////////
// Function to suggest a level based on the values filled on the fields
// vegetationTypes -> Different types of vegetation
// puddleCoverage -> % of space covered by puddle in 3 levels (|<50%| |<75%| |>75%|) levels 1 to 3 (integer)
// degradationCoverage -> % of space covered by bushes in 3 levels (|>50%| |<50%| |<25%|) levels 1 to 3 (integer)
// Returns Levels 1-4 (Bad, Medium, Good, Excellent)

export const levelSuggestionD34 = (
  vegetationTypes,
  puddleCoverage,
  degradationCoverage,
) => {
  if (
    vegetationTypes >= 3 &&
    puddleCoverage === 3 &&
    degradationCoverage === 3
  ) {
    return 4;
  }
  if (vegetationTypes >= 2 && puddleCoverage >= 2 && degradationCoverage >= 2) {
    return 3;
  }
  if (vegetationTypes >= 1) {
    return 2;
  }
  return 1;
};

// ///////////////////////////
//           D3.5           //
// ///////////////////////////
// Function to suggest a level based on the values filled on the fields
// bushes -> If there is bushes or not
// vegetation -> If there is vegetation or not
// vegetationCoverage -> % of space covered by vegetyation in 3 levels (|<50%| |<75%| |>75%|) levels 1 to 3 (integer)
// slopeCoverage -> % of margin with sligth slope in 3 levels (|<25%| |<50%| |>50%|) levels 1 to 3 (integer)
// Returns Levels 1-4 (Bad, Medium, Good, Excellent)

export const levelSuggestionD35 = (
  bushes,
  vegetation,
  vegetationCoverage,
  slopeCoverage,
) => {
  if (vegetation && vegetationCoverage === 3 && slopeCoverage === 3) {
    return 4;
  }
  if (vegetation && vegetationCoverage === 2 && slopeCoverage === 3) {
    return 3;
  }
  if (!(bushes === false && vegetation === false) && slopeCoverage >= 2) {
    return 2;
  }
  return 1;
};

// ///////////////////////////
//          D3.6.a          //
// ///////////////////////////
// Function to suggest a level based on the values filled on the fields
// vegetationTypes -> Different types of vegetation
// riparianGalleryCoverage -> % of space covered by riparian gallery in 4 levels (|<5%| |<25%| |<50%| |>50%|) levels 1 to 4 (integer)
// Returns Levels 1-4 (Bad, Medium, Good, Excellent)

export const levelSuggestionD36A = (
  vegetationTypes,
  riparianGalleryCoverage,
) => {
  if (vegetationTypes >= 2 && riparianGalleryCoverage === 4) {
    return 4;
  }
  if (vegetationTypes >= 2 && riparianGalleryCoverage === 3) {
    return 3;
  }
  if (vegetationTypes >= 1 && riparianGalleryCoverage >= 2) {
    return 2;
  }
  return 1;
};

// ///////////////////////////
//          D3.6.b          //
// ///////////////////////////
// Function to suggest a level based on the values filled on the fields
// vegetation -> Vegetation Present
// vines -> Vines Present
// vinesDeveloped -> Vines Really Developed
// vegetationCoverage -> % of space covered by vegetation in 4 levels (|<25%| |<50%| |<75%| |>75%|) levels 1 to 4 (integer)
// Returns Levels 1-4 (Bad, Medium, Good, Excellent)

export const levelSuggestionD36B = (
  vegetation,
  vines,
  vinesDeveloped,
  vegetationCoverage,
) => {
  if (vegetation && vines && vinesDeveloped && vegetationCoverage === 4) {
    return 4;
  }
  if (vegetation && vines && vinesDeveloped && vegetationCoverage === 3) {
    return 3;
  }
  if (vegetation && vines && vegetationCoverage >= 2) {
    return 2;
  }
  return 1;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

export const createPhotoId = () => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 12) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const groupEvaluationsByEvaluationId = (data) => {
  if (!data) {
    return [];
  }

  const grouped = data.reduce((acc, curr) => {
    // If we haven't seen this evaluationId, add the object (with a cloned evaluation array)
    if (!acc[curr.evaluationId]) {
      acc[curr.evaluationId] = { ...curr, evaluation: [...curr.evaluation] };
    } else {
      // If the evaluationId already exists, merge the evaluation arrays.
      acc[curr.evaluationId].evaluation = acc[
        curr.evaluationId
      ].evaluation.concat(curr.evaluation);
    }

    delete acc[curr.evaluationId].pointId;

    return acc;
  }, {});

  // Return the grouped entries as an array
  return Object.values(grouped);
};

export const groupEvaluationsImagesByEvaluationId = (data) => {
  if (!data) {
    return [];
  }

  const grouped = data.reduce((acc, curr) => {
    const id = curr.evaluationId;
    if (!acc[id]) {
      // Clone the current object and its files.answers array
      acc[id] = {
        ...curr,
        files: {
          ...curr.files,
          answers: [...curr.files.answers],
        },
      };
    } else {
      // Merge the files.answers arrays
      acc[id].files.answers = acc[id].files.answers.concat(curr.files.answers);
    }
    return acc;
  }, {});

  return Object.values(grouped);
};
