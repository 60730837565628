import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Container, Label, Error } from './ValidatedRecoverPasswordForm.styles';
import { colors } from '../../constants/colors';
import { recoverPassword } from '../../store/actions/auth';
import CustomInput from '../CustomInput/CustomInput';
import CustomButton from '../CustomButton/CustomButton';

const ValidatedRecoverPasswordForm = ({ close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const recoverClickHandler = (values) => {
    dispatch(recoverPassword(values.email))
      .then(() => {
        // eslint-disable-next-line no-undef
        alert(t('email_sent'));
        close();
      })
      .catch((error) => {
        // eslint-disable-next-line no-undef
        alert(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('invalid_email')).required(t('required')),
    }),
    onSubmit: (values) => {
      recoverClickHandler(values);
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Label htmlFor="email">{t('email')}</Label>
      <CustomInput
        id="email"
        name="email"
        type="email"
        placeholder={t('email_placeholder')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
      />
      {formik.touched.email && formik.errors.email ? (
        <Error>{formik.errors.email}</Error>
      ) : null}
      <CustomButton
        style={{
          marginTop: 40,
          marginBottom: 0,
          width: '100%',
          borderRadius: '5px',
        }}
        text={t('recover_password')}
        backgroundColor={loading ? colors.grey : colors.primaryColor}
        loading={loading}
        disabled={loading}
        type="submit"
      />
    </Container>
  );
};

export default ValidatedRecoverPasswordForm;
