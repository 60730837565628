import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Label,
  Error,
  Visible,
  PasswordContainer,
} from './ValidatedLoginForm.styles';
import { colors } from '../../constants/colors';
import { login } from '../../store/actions/auth';
import CustomInput from '../CustomInput/CustomInput';
import CustomButton from '../CustomButton/CustomButton';
import showHide from '../../assets/icons/show-hide.svg';
import showHideActive from '../../assets/icons/show-hide-active.svg';

const ValidatedLoginForm = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const loginClickHandler = (values) => {
    dispatch(login(values.username, values.password)).catch((error) => {
      // eslint-disable-next-line no-undef
      alert(error);
    });
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t('required')),
      password: Yup.string().required(t('required')),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      loginClickHandler(values);
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Label htmlFor="username">{t('username')}</Label>
      <CustomInput
        id="username"
        name="username"
        type="username"
        placeholder={t('username_placeholder')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.username}
      />
      {formik.touched.username && formik.errors.username ? (
        <Error>{formik.errors.username}</Error>
      ) : null}
      <Label htmlFor="password">{t('password')}</Label>
      <PasswordContainer>
        <CustomInput
          id="password"
          name="password"
          type={visible ? 'text' : 'password'}
          placeholder={t('password_placeholder')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />
        <Visible
          src={visible ? showHideActive : showHide}
          onClick={() => setVisible(!visible)}
        />
      </PasswordContainer>

      {formik.touched.password && formik.errors.password ? (
        <Error>{formik.errors.password}</Error>
      ) : null}
      <CustomButton
        style={{
          marginTop: 40,
          marginBottom: 20,
          width: '100%',
          borderRadius: '5px',
        }}
        text={t('login')}
        backgroundColor={loading ? colors.grey : colors.primaryColor}
        loading={loading}
        disabled={loading}
        type="submit"
      />
    </Container>
  );
};

export default ValidatedLoginForm;
