import axios, { Endpoints, getErrorMessage } from '../../services/api';
import { HTTP_STATUS } from '../../constants';

import { getAuthToken } from '../../services/local';

export const updateSubplotAP = (plotId, values) => async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  const body = {
    subplotId: plotId,
    latitudeAccess: values?.latitude?.toString(),
    longitudeAccess: values?.longitude?.toString(),
  };

  try {
    const response = await axios.post(
      Endpoints.UPDATE_SUBPLOT_AP,
      body,
      config,
    );
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

export const createSubplotPoint = (plotId, values) => async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  const body = {
    subplotId: plotId,
    identification: values?.identification,
    area: values?.area,
    latitudeAccess: values?.latitude?.toString(),
    longitudeAccess: values?.longitude?.toString(),
    pointTypeId: values?.type,
  };

  try {
    const response = await axios.post(
      Endpoints.CREATE_SUBPLOT_POINT,
      body,
      config,
    );
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

export const updateSubplotPoint = (pointId, values) => async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  const body = {
    pointId,
    area: values?.area,
    latitudeAccess: values?.latitude?.toString(),
    longitudeAccess: values?.longitude?.toString(),
  };

  try {
    const response = await axios.post(
      Endpoints.UPDATE_SUBPLOT_POINT,
      body,
      config,
    );
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};
