import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import {
  Page,
  Container,
  Logo,
  FormContainer,
  Url,
} from './LoginScreen.styles';
import ValidatedLoginForm from '../../components/ValidatedLoginForm/ValidatedLoginForm';
import logo from '../../assets/images/logo.svg';
import PopUp from '../../components/PopUp/PopUp';
import ValidatedRecoverPasswordForm from '../../components/ValidatedRecoverPasswordForm/ValidatedRecoverPasswordForm';
import { isAuthenticated } from '../../services/local';

const LoginScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const [recoverPasswordPopUp, setRecoverPasswordPopUp] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const close = () => {
    setRecoverPasswordPopUp(false);
  };

  useEffect(() => {
    if (user !== null && isAuthenticated()) {
      navigate(routes.PROPERTIES.path, { replace: true });
    }
  }, [user, routes.PROPERTIES.path, navigate]);

  return (
    <Page>
      <Container>
        <PopUp open={recoverPasswordPopUp} close={close}>
          <ValidatedRecoverPasswordForm close={close} />
        </PopUp>
        <Logo src={logo} />
        <FormContainer>
          <ValidatedLoginForm />
          <Url onClick={() => setRecoverPasswordPopUp(true)}>
            {t('recover_password')}
          </Url>
        </FormContainer>
      </Container>
    </Page>
  );
};

export default LoginScreen;
