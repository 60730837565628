import React from 'react';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import { Wrapper } from './SmallMap.styles';
import 'leaflet/dist/leaflet.css';
import { customIcon, locationIcon } from '../../helpers/mapIcons';

const SmallMap = ({ center, subplot }) => (
  <Wrapper>
    {center && (
      <MapContainer
        center={[center?.lat, center?.lng]}
        zoom={16}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {subplot?.polyline && <Polyline positions={subplot.polyline} />}
        <Marker
          eventHandlers={[center?.lat, center?.lng]}
          position={center}
          icon={locationIcon}
        />
        {subplot?.markers?.map((marker, index) => (
          <Marker
            key={index}
            eventHandlers={[marker?.lat, marker?.lng]}
            position={[marker?.lat, marker?.lng]}
            icon={customIcon}
          />
        ))}
      </MapContainer>
    )}
  </Wrapper>
);

export default SmallMap;
