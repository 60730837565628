import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath, useParams } from 'react-router-dom';
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Popup,
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import {
  Page,
  Container,
  ButtonContainer,
  SpinnerWrapper,
} from './PropertyMapScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import CustomButton from '../../components/CustomButton/CustomButton';
import { colors } from '../../constants/colors';
import addIcon from '../../assets/icons/plus.svg';
import MapTooltip from '../../components/MapTooltip/MapTooltip';
import { getCurrentLocation } from '../../services/geolocation';
import { resetStateProperty } from '../../store/actions/properties';
import PopUp from '../../components/PopUp/PopUp';
import { getDownloadAlert } from '../../services/local';
import ValidatedDownloadAlertForm from '../../components/ValidatedDownloadAlertForm/ValidatedDownloadAlertForm';
import { customIcon, locationIcon } from '../../helpers/mapIcons';

const PropertyMapScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const savedProperties = useSelector((state) => state.savedData.properties);
  const property = savedProperties.find((item) => item?.id === params?.id);

  const [center, setCenter] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [showAlert, setShowAlert] = useState(!getDownloadAlert());

  useEffect(() => {
    if (!center?.length && property?.lat && property?.lng)
      setCenter([Number(property?.lat), Number(property?.lng)]);
  }, [center, property]);

  useEffect(() => {
    if (!currentLocation) {
      getCurrentLocation().then((value) =>
        setCurrentLocation([value?.lat, value?.lng]),
      );
    }
  }, [currentLocation]);

  const backHandler = () => {
    dispatch(resetStateProperty());
    navigate(-1);
  };

  const lastEvalutionDate = property?.subplots?.reduce((acc, curr) => {
    if (acc < curr?.lastEvaluationDate) {
      return curr?.lastEvaluationDate;
    }
    return acc;
  }, null);

  return (
    <Page>
      <TopBar
        back={backHandler}
        title={property?.name}
        download={!!params?.id}
        propertyId={params?.id}
      />
      <PopUp open={showAlert && !property}>
        <ValidatedDownloadAlertForm
          onClose={() => setShowAlert(false)}
          propertyId={params?.id}
        />
      </PopUp>
      {currentLocation?.length ? (
        <Container>
          <MapContainer
            center={currentLocation}
            zoom={14}
            scrollWheelZoom={false}
            whenReady={(map) => {
              map.target.on('click', (e) => {
                navigate(
                  generatePath(routes.UPDATE_AP_PROPERTY.path, {
                    id: params?.id,
                  }),
                  { state: { selectedPos: e?.latlng } },
                );
              });
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {property?.polyline && <Polyline positions={property.polyline} />}
            {center?.length && (
              <Marker position={center} icon={customIcon}>
                <Marker position={currentLocation} icon={locationIcon} />
                <Popup minWidth={140} closeButton={false} className="popup-map">
                  <MapTooltip>
                    <div className="wrapper">
                      <span className="label">{t('gps_coords')}</span>
                      <span className="value">{`${center[0]}; ${center[1]}`}</span>
                    </div>
                    <div className="wrapper">
                      <span className="label">{t('upload_date')}</span>
                      <span className="value">
                        {lastEvalutionDate
                          ? moment(new Date(lastEvalutionDate)).format(
                              'DD/MM/YYYY',
                            )
                          : '-'}
                      </span>
                    </div>
                  </MapTooltip>
                </Popup>
              </Marker>
            )}
          </MapContainer>
        </Container>
      ) : (
        <SpinnerWrapper>
          <Spinner animation="border" variant="dark" />
        </SpinnerWrapper>
      )}
      <ButtonContainer>
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          icon={addIcon}
          text={t('update_ap')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(
              generatePath(routes.UPDATE_AP_PROPERTY.path, { id: params?.id }),
            );
          }}
        />
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          text={t('subplots')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(generatePath(routes.SUBPLOTS.path, { id: params?.id }));
          }}
        />
      </ButtonContainer>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default PropertyMapScreen;
