import Select from 'react-select';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const StyledSelect = styled(Select)`
  .react-select__indicator-separator {
    visibility: hidden;
  }

  .react-select__control {
    height: 30px;
    min-height: 30px;
    width: 100%;
    outline: none;
    border-color: 1px solid ${colors.grey};
    border-radius: 5px;
    flex-wrap: nowrap;
    background-color: ${colors.white};

    .react-select__option {
      padding: 0;
    }

    .react-select__value-container {
      padding-left: 10px !important;
      padding-right: 10px;
      flex-wrap: nowrap;
      display: flex;
      padding: 0;

      .react-select__placeholder {
        color: ${colors.grey};
      }

      .react-select__single-value {
        color: ${colors.grey};
      }

      .react-select__input {
        color: ${colors.grey};
      }
    }

    .react-select__indicators,
    .react-select__dropdown-indicator {
      color: ${colors.primaryColor};
      background-color: ${colors.gray};
      overflow: hidden;
    }
  }

  .react-select__control--is-focused {
    // box-shadow: none;
    // border: none;
    outline-color: black;
  }

  .react-select__menu {
    color: ${colors.grey};

    .react-select__option--is-selected {
      background-color: ${colors.primaryColor};
    }
  }
`;
