import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  width: 100%;
`;

export const Label = styled.label`
  font-weight: 700;
  color: ${colors.primaryColor};
  font-size: 16px;
  margin-top: 30px;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const Error = styled.span`
  color: ${colors.red};
  font-size: 14px;
  height: 0;
`;

export const PasswordContainer = styled.div`
  position: relative;
`;

export const Visible = styled.img`
  right: 0;
  left: auto;
  position: absolute;
  margin-top: 9px;
`;
