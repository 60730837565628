import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 100%;
  height: 100vh;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 130px;
`;

export const Label = styled.label`
  font-weight: 700;
  color: ${colors.text};
  font-size: 16px;
  margin-bottom: 10px;
`;

export const Error = styled.span`
  color: ${colors.red};
  font-size: 14px;
  height: 0;
`;

export const PasswordContainer = styled.div`
  position: relative;
`;

export const Visible = styled.img`
  right: 0;
  left: auto;
  position: absolute;
  margin-top: 9px;
`;
