import React from 'react';
import {
  Container,
  Title,
  Description,
  SubTitle,
  TableHeader,
  TableWrapper,
} from './HelpGuide.styles';

const data = {
  header: 'Análise',
  description:
    'Observar o estrato herbáceo, incluindo zonas interior e exterior à projeção da copa das árvores.',
  subtitle: 'Legenda',
  scaleInfo: [
    {
      color: 'red',
      header: 'Muito Baixo',
      description:
        'Ausência ou presença residual de leguminosas e de “outras”. Dominância de gramíneas, sem diversidade.',
    },
    {
      color: 'orange',
      header: 'Baixo',
      description:
        'Ausência ou presença residual de leguminosas. Presença de “outras”, com dominância de gramíneas. Com diversidade nos grupos de gramíneas e “outras”.',
    },
    {
      color: 'yellow',
      header: 'Moderado',
      description:
        'Ausência ou presença residual de leguminosas. Presença de gramíneas, com dominância de “outras”. Com diversidade nos grupos de gramíneas e “outras”.',
    },
    {
      color: 'green',
      header: 'Elevado',
      description:
        'Presença equilibrada dos 3 grupos (presença de leguminosas >5% de cobertura) e com diversidade em cada grupo. Nota: Se o grupo das leguminosas não tiver diversidade, a classificação passa a MODERADO.',
    },
  ],
};

const HelpGuide = () => (
  <Container>
    <Title>{data.header}</Title>
    <Description>{data.description}</Description>
    <SubTitle>{data.subtitle}</SubTitle>
    {data.scaleInfo?.map((level, index) => (
      <div key={index}>
        <TableHeader className={level.color}>{level.header}</TableHeader>
        <TableWrapper>{level.description}</TableWrapper>
      </div>
    ))}
  </Container>
);

export default HelpGuide;
