import {
  GENERAL_HELP_SUCCESS,
  POINT_TYPE_INDICATORS_SUCCESS,
  POINT_TYPES_SUCCESS,
  PROPERTY_SAVE_SUCCESS,
} from '../actions/types';

const initialState = {
  properties: [],
  generalHelp: [],
  pointTypeIndicators: {},
  pointTypes: [],
};

const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTY_SAVE_SUCCESS:
      return {
        ...state,
        properties: [...state.properties, action.property],
        loading: false,
      };
    case GENERAL_HELP_SUCCESS:
      return {
        ...state,
        generalHelp: action.generalHelp,
      };
    case POINT_TYPE_INDICATORS_SUCCESS:
      return {
        ...state,
        pointTypeIndicators: action.pointTypeIndicators,
      };
    case POINT_TYPES_SUCCESS:
      return {
        ...state,
        pointTypes: action.pointTypes,
      };
    default:
      return state;
  }
};

export default propertiesReducer;
