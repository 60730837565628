import axios, { Endpoints, getErrorMessage } from '../../services/api';
import {
  PROPERTY_SAVE_SUCCESS,
  POINT_TYPE_INDICATORS_SUCCESS,
  POINT_TYPES_SUCCESS,
  GENERAL_HELP_SUCCESS,
} from './types';
import { HTTP_STATUS } from '../../constants';
import { getAuthToken } from '../../services/local';
import { extractPoints, extractPolyline } from '../../helpers/utils';

const saveProperty = (id) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  const url = Endpoints.PROPERTY.concat('?propertyId=').concat(id);
  try {
    const response = await axios.get(url, config);
    const statusCode = response.status;

    const property = response?.data?.dataObject?.status;

    if (statusCode === HTTP_STATUS.SUCCESS && property) {
      if (property.documents.length) {
        const kml = await fetch(
          property.documents[0].urlPath?.replace('http:', 'https:'),
        );
        const kmlText = await kml.text();
        const plot = await extractPolyline(kmlText);

        property.polyline = plot?.polyline;
      }

      await Promise.all(
        property.subplots.map(async (subPlot) => {
          await Promise.all(
            subPlot.documents.map(async (document) => {
              const kml = await fetch(
                document.urlPath?.replace('http:', 'https:'),
              );
              const kmlText = await kml.text();

              if (document.type === 'MapaPontosSubparcela') {
                const result = await extractPoints(kmlText);
                subPlot.markers = result?.markers;
              }

              if (document.type === 'MapaSubparcela') {
                const plot = await extractPolyline(kmlText);
                subPlot.polyline = plot?.polyline;
              }
            }),
          );
        }),
      );

      dispatch({
        type: PROPERTY_SAVE_SUCCESS,
        property,
      });
      return Promise.resolve(property);
    }
  } catch (error) {
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

const getGeneralHelp = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  try {
    const response = await axios.get(Endpoints.GET_GENERAL_HELP, config);
    const statusCode = response.status;

    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: GENERAL_HELP_SUCCESS,
        generalHelp: response?.data?.dataObject?.status,
      });
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

const getPointTypeIndicators = async (pointTypeId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  const url =
    Endpoints.GET_POINT_TYPE_INDICATORS.concat('?pointTypeId=').concat(
      pointTypeId,
    );

  try {
    const response = await axios.get(url, config);
    const statusCode = response.status;

    if (statusCode === HTTP_STATUS.SUCCESS) {
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

const getPointTypes = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  const url = Endpoints.GET_POINT_TYPES;

  try {
    const response = await axios.get(url, config);
    const statusCode = response.status;

    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: POINT_TYPES_SUCCESS,
        pointTypes: response?.data?.dataObject?.status,
      });
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

export const saveData = (propertyId) => async (dispatch) => {
  try {
    const property = await dispatch(saveProperty(propertyId));
    await dispatch(getGeneralHelp());
    await dispatch(getPointTypes());

    const pointTypeIndicators = {};

    // get points type indicators
    const allIndicatorsPromises = property.subplots.flatMap((subPlot) =>
      subPlot.points.map((point) =>
        getPointTypeIndicators(point.pointType.id).then((result) => ({
          id: point.pointType.id,
          result,
        })),
      ),
    );
    const indicatorsResults = await Promise.all(allIndicatorsPromises);
    indicatorsResults.forEach(({ id, result }) => {
      pointTypeIndicators[id] = result;
    });

    dispatch({
      type: POINT_TYPE_INDICATORS_SUCCESS,
      pointTypeIndicators,
    });

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};
