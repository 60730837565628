// Auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

// Properties
export const PROPERTIES_START = 'PROPERTIES_START';
export const PROPERTIES_SUCCESS = 'PROPERTIES_SUCCESS';
export const PROPERTIES_ERROR = 'PROPERTIES_ERROR';

// Property
export const UPDATE_PROPERTY_AP_START = 'UPDATE_PROPERTY_AP_START';
export const UPDATE_PROPERTY_AP_SUCCESS = 'UPDATE_PROPERTY_AP_SUCCESS';
export const UPDATE_PROPERTY_AP_ERROR = 'UPDATE_PROPERTY_AP_ERROR';

// TODO: maybe to remove the following actions
export const PROPERTY_START = 'PROPERTY_START';
export const PROPERTY_SUCCESS = 'PROPERTY_SUCCESS';
export const PROPERTY_ERROR = 'PROPERTY_ERROR';
export const PROPERTY_RESET_STATE = 'PROPERTY_RESET_STATE';

// Saved Data
export const PROPERTY_SAVE_SUCCESS = 'PROPERTY_SAVE_SUCCESS';
export const GENERAL_HELP_SUCCESS = 'GENERAL_HELP_SUCCESS';
export const POINT_TYPE_INDICATORS_SUCCESS = 'POINT_TYPE_INDICATORS_SUCCESS';
export const POINT_TYPES_SUCCESS = 'POINT_TYPES_SUCCESS';

// EVALUATION
export const EVALUATION_SEND = 'EVALUATION_SEND';
export const EVALUATION_SEND_SUCCESS = 'EVALUATION_SEND_SUCCESS';
export const EVALUATION_SEND_ERROR = 'EVALUATION_SEND_ERROR';
export const EVALUATION_SEND_IMAGES = 'EVALUATION_SEND_IMAGES';
export const EVALUATION_SEND_IMAGES_SUCCESS = 'EVALUATION_SEND_IMAGES_SUCCESS';
export const EVALUATION_SEND_IMAGES_ERROR = 'EVALUATION_SEND_IMAGES_ERROR';

// STATS
export const STATS = 'STATS';
export const STATS_SUCCESS = 'STATS_SUCCESS';
export const STATS_ERROR = 'STATS_ERROR';
