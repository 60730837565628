/* eslint-disable no-undef */
export const AUTH_TOKEN_KEY = '@auth-Token';
export const DOWNLOAD_ALERT_KEY = '@download-Token';
export const EVALUATION_DATA = '@evaluation-data';
export const EVALUATION_FILES_DATA = '@evaluation-files-data';

export function isAuthenticated() {
  return localStorage.getItem(AUTH_TOKEN_KEY) !== null;
}

export function getAuthToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function saveAuthToken(authToken) {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
}

export function clearLocalStorage() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
}

export function setDownloadAlert() {
  localStorage.setItem(DOWNLOAD_ALERT_KEY, true);
}

export function getDownloadAlert() {
  return localStorage.getItem(DOWNLOAD_ALERT_KEY) !== null;
}

export function saveEvaluationData(evaluation) {
  if (
    evaluation === null ||
    evaluation === undefined ||
    evaluation?.length === 0 ||
    evaluation === ''
  ) {
    localStorage.removeItem(EVALUATION_DATA);
    return;
  }

  localStorage.setItem(EVALUATION_DATA, evaluation);
}

export function saveEvaluationFilesData(files) {
  if (
    files === null ||
    files === undefined ||
    files?.length === 0 ||
    files === ''
  ) {
    localStorage.removeItem(EVALUATION_FILES_DATA);
    return;
  }

  localStorage.setItem(EVALUATION_FILES_DATA, files);
}

export function getEvaluationData() {
  return JSON.parse(localStorage.getItem(EVALUATION_DATA));
}

export function getEvaluationFilesData() {
  return JSON.parse(localStorage.getItem(EVALUATION_FILES_DATA));
}
